import { ssrRef, useContext, onBeforeMount } from '@nuxtjs/composition-api';

export default () => {
  const { error: showErrorPage, nuxtState } = useContext();
  const error = ssrRef('false', 'error');

  onBeforeMount(() => {
    if (error.value === 'true') {
      showErrorPage({ statusCode: 404 });
      nuxtState.ssrRefs.error = 'false';
    }
  });

  return {
    error,
    showErrorPage,
  };
};
