import { ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import { Faq, FaqsParams, Meta } from '@webplatform/cms-js-sdk';

export default (params: FaqsParams) => {
  const {
    $api: { faqsService },
  } = useContext();
  const faqs = ref<Faq[]>();
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const meta = ref<Meta>();

  const fetchFaqs = async () => {
    error.value = false;
    pending.value = true;

    try {
      const { data, meta: faqsMeta } = await faqsService.getAllFaqs(params);
      faqs.value = data;
      meta.value = faqsMeta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    faqs,
    meta,
    pending,
    error,
    fetchFaqs,
  };
};
