import { useMeta, Ref } from '@nuxtjs/composition-api';

export default (page: Ref) => {
  useMeta(() => ({
    title: page.value?.seo_title || page.value?.seo?.page_title,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: page.value?.seo_description || page.value?.seo?.description,
      },
      {
        property: 'og:title',
        content: page.value?.seo_og_title || page.value?.seo?.og_title,
      },
      {
        property: 'og:description',
        content: page.value?.seo_description || page.value?.seo?.description,
      },
    ],
  }));
};
