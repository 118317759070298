import { ref, useContext } from '@nuxtjs/composition-api';
import { Params } from '@webplatform/cms-js-sdk';
import useErrorPage from './useErrorPage';
import useMeta from './useMeta';

const NEWS_TYPE = 'news';

export default (slug: string, params: Params = {}) => {
  const {
    $api: { newsService },
    ssrContext,
  } = useContext();
  const { error, showErrorPage } = useErrorPage();
  const pending = ref(false);
  const newsArticle = ref();

  const fetchNewsArticle = async () => {
    try {
      pending.value = true;
      const { data } = await newsService.getNewsArticleBySlug(slug, {
        type: NEWS_TYPE,
        ...params,
      });
      newsArticle.value = data;
    } catch (e) {
      if (ssrContext) {
        ssrContext.res.statusCode = 404;
        error.value = 'true';
      } else showErrorPage({ statusCode: 404 });
    } finally {
      pending.value = false;
    }
  };

  useMeta(newsArticle);

  return {
    newsArticle,
    pending,
    fetchNewsArticle,
  };
};
