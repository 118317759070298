import { DocumentsResponse } from '@webplatform/cms-js-sdk';
import {
  DocumentsFilters,
  DocumentsGroupFilter,
  DocumentsGrouping,
} from '../types';

const splitByGroups = (filters: DocumentsGrouping) => {
  const groups: Record<string, DocumentsGroupFilter> = {};

  const addToGroup = (param: DocumentsGroupFilter, type = 'all') => {
    if (!groups[type]) groups[type] = { ...param };
    else {
      const { include, exclude } = param;
      if (include)
        groups[type].include = [...(groups[type].include ?? []), ...include];
      if (exclude)
        groups[type].exclude = [...(groups[type].exclude ?? []), ...exclude];
    }
  };

  if (Array.isArray(filters)) addToGroup({ include: filters });
  else if (typeof filters === 'object') {
    if (filters.include || filters.exclude)
      addToGroup({ ...(filters as DocumentsGroupFilter) });
    else {
      Object.entries(filters as Record<string, DocumentsFilters>).forEach(
        ([type, filter]) => {
          if (Array.isArray(filter)) addToGroup({ include: filter }, type);
          else addToGroup({ ...filter }, type);
        }
      );
    }
  }

  return groups;
};

export default (
  documents: DocumentsResponse,
  grouping: DocumentsGrouping = {}
): DocumentsResponse => {
  const groups = splitByGroups(grouping);
  const result: DocumentsResponse = Object.keys(groups).reduce(
    (keys, key) => ({ ...keys, [key]: [] }),
    {}
  );

  Object.entries(documents).forEach((entry) => {
    const [group, items] = entry;

    Object.keys(groups).forEach((groupKey) => {
      const groupFilter = groups[groupKey];
      const { include, exclude } = groupFilter;

      if (
        (include && !include.includes(group)) ||
        (exclude && exclude.includes(group))
      )
        return;

      result[groupKey].push(...items);
    });
  });

  return result;
};
