import { ComputedRef, ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import {
  DocumentsParams,
  DocumentsGroupedParams,
  DocumentsResponse,
  DocumentsGroupedResponse,
  Meta,
} from '@webplatform/cms-js-sdk';
import groupDocuments from '../helpers/groupDocuments';
import groupDocumentsBySku from '../helpers/groupDocumentsBySku';
import { DocumentsGrouping } from '../types';

type Params = DocumentsParams | DocumentsGroupedParams;

export default (
  params: ComputedRef<Params>,
  groupBy?: 'product' | 'sku' | false,
  grouping?: DocumentsGrouping
) => {
  const {
    $api: { documentsService },
  } = useContext();
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const documents = ref<DocumentsResponse | DocumentsGroupedResponse>();
  const meta = ref<Meta>();

  const fetchDocumentsStrategy = () => {
    if (groupBy) {
      if (groupBy === 'product')
        return documentsService.getDocumentsGroupedByProducts(
          params.value as DocumentsGroupedParams
        );

      if (groupBy === 'sku')
        return documentsService.getDocumentsGroupedBySku(
          params.value as DocumentsGroupedParams
        );
    }
    return documentsService.getAllDocuments(params.value as DocumentsParams);
  };

  const groupDocumentsStrategy = (
    items: DocumentsResponse | DocumentsGroupedResponse
  ) => {
    if (!grouping || groupBy === 'sku') return items;

    return groupBy === 'product'
      ? groupDocumentsBySku(items as DocumentsGroupedResponse, grouping)
      : groupDocuments(items as DocumentsResponse, grouping);
  };

  const fetchDocuments = async () => {
    error.value = false;
    pending.value = true;

    try {
      const response = await fetchDocumentsStrategy();
      documents.value = groupDocumentsStrategy(response.data);
      meta.value = response.meta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    documents,
    meta,
    pending,
    error,
    fetchDocuments,
  };
};
