import {
  DocumentsGroupedResponse,
  DocumentsResponse,
} from '@webplatform/cms-js-sdk';
import groupDocuments from './groupDocuments';
import { DocumentsGrouping } from '../types';

export default (
  documents: DocumentsGroupedResponse,
  grouping: DocumentsGrouping
) =>
  Object.entries(documents).reduce((items, item) => {
    const [sku, documentsSection] = item;
    const grouped = groupDocuments(documentsSection, grouping);

    return {
      ...items,
      [sku]: Object.entries(grouped).reduce(
        (groupItems, [groupName, groupDocs]) => ({
          ...groupItems,
          [groupName]: [...(groupItems[groupName] ?? []), ...groupDocs],
        }),
        {} as DocumentsResponse
      ),
    };
  }, {});
