import { ref, useContext } from '@nuxtjs/composition-api';
import { Params } from '@webplatform/cms-js-sdk';
import useErrorPage from './useErrorPage';
import useMeta from './useMeta';

export default (slug: string, params: Params = {}) => {
  const {
    $api: { pagesService },
    ssrContext,
  } = useContext();
  const { error, showErrorPage } = useErrorPage();
  const pending = ref(false);
  const page = ref();

  const fetchPage = async () => {
    try {
      pending.value = true;
      const { data } = await pagesService.getPageBySlug(slug, params);
      page.value = data;
    } catch (e) {
      if (ssrContext) {
        ssrContext.res.statusCode = 404;
        error.value = 'true';
      } else showErrorPage({ statusCode: 404 });
    } finally {
      pending.value = false;
    }
  };

  useMeta(page);

  return {
    page,
    pending,
    fetchPage,
  };
};
