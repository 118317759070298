import { useContext, ref, ssrRef } from '@nuxtjs/composition-api';
import { FaqCategory, Meta, Params } from '@webplatform/cms-js-sdk';

export default (params: Params) => {
  const {
    $api: { faqCategoriesService },
  } = useContext();
  const pending = ref(false);
  const error = ssrRef(false, 'error');
  const faqCategories = ref<FaqCategory[]>();
  const meta = ref<Meta>();
  const fetchFaqCategories = async () => {
    error.value = false;
    pending.value = true;
    try {
      const { data, meta: faqCategoriesMeta } =
        await faqCategoriesService.getAllFaqsCategories(params);
      faqCategories.value = data;
      meta.value = faqCategoriesMeta;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    faqCategories,
    meta,
    pending,
    error,
    fetchFaqCategories,
  };
};
