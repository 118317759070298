import { ref, useContext } from '@nuxtjs/composition-api';
import { Params } from '@webplatform/cms-js-sdk';
import useErrorPage from './useErrorPage';
import useMeta from './useMeta';

export default (slug: string, params: Params = {}) => {
  const {
    $api: { productsService },
    ssrContext,
  } = useContext();
  const { error, showErrorPage } = useErrorPage();
  const product = ref();
  const pending = ref(false);

  const fetchProduct = async () => {
    try {
      pending.value = true;
      const { data } = await productsService.getProductBySlug(slug, params);
      product.value = data;
    } catch (e) {
      if (ssrContext) {
        ssrContext.res.statusCode = 404;
        error.value = 'true';
      } else showErrorPage({ statusCode: 404 });
    } finally {
      pending.value = false;
    }
  };

  useMeta(product);

  return { product, pending, error, fetchProduct };
};
