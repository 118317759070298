import { ref, useContext } from '@nuxtjs/composition-api';
import { Meta, Params } from '@webplatform/cms-js-sdk';

export default (params: Params = {}) => {
  const {
    $api: { productsService },
  } = useContext();
  const products = ref();
  const meta = ref<Meta>();
  const pending = ref(false);
  const error = ref();

  const fetchProducts = async () => {
    try {
      error.value = null;
      pending.value = true;
      const { data, meta: productsMeta } = await productsService.getAllProducts(params);
      products.value = data;
      meta.value = productsMeta;
    } catch (e) {
      // @ts-ignore
      error.value = e.message || e;
    } finally {
      pending.value = false;
    }
  };

  return {
    products,
    meta,
    pending,
    error,
    fetchProducts,
  };
};
